.main-container {
  width: 100%;
  height: 20vh;
  background-color: #000000;
  font-size: 11vmin;
  line-height: 9vmin;
  font-family: Clock, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;

  .mode {
    margin: 1vmin;
    color: #37eb34;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .score {
    color: #ed1527;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

