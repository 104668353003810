$primary: #e5dc15;
$secondary: #000000;

.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;


  .pushable{
    text-align: center;
    user-select: none;
    box-sizing: border-box;
    color: $secondary;
    background-color: $primary;
    text-transform: uppercase;
    border: 1vmin solid $secondary;
    border-radius: 1vmin;
    transition: all 100ms ease-in-out;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 7vmin;
    font-weight: bold;

    &:active {
      color: $primary;
      background-color: $secondary;
      border: 1vmin solid $primary;
    }
  }
}
