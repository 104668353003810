@font-face {
  font-family: 'Clock';
  src: url('../public/fonts/clock.ttf') format('truetype'), url('../public/fonts/clock.woff') format('woff'),
  url('../public/fonts/clock.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Futura';
  src: url('../public/fonts/futura.ttf') format('truetype'), url('../public/fonts/futura.woff') format('woff'),
  url('../public/fonts/futura.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: Futura,'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("../public/img/rubber.jpg");
  background-size: 20%;
  background-color: #fafafa;
}

.overflow-auto {
  overflow: auto;
}

